<template>
  <div class="ui-tag" :class="[variant, error]">
    <Typography
      :class="canClose ? 'mr-2' : ''"
      line-height="1.3"
      :color="color || error"
      :bold="bold"
    >
      {{ label }}
    </Typography>
    <Tooltip v-if="error === 'error'" :content="errorMessage">
      <Icon
        name="alert"
        :color="error"
        :class="canClose ? 'mr-2' : 'ml-2'"
        sides="12"
      />
    </Tooltip>
    <IconButton
      v-if="canClose"
      v-ripple
      icon-name="close"
      sides="16"
      class="ui-tag-close"
      @click="onClickClose"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography';
import Icon from '@/components/ui/Icon.vue';
import IconButton from '@/components/ui/IconButton';
import Tooltip from '@/components/ui/Tooltip.vue';

export default defineComponent({
  name: 'Tag',
  components: { Typography, IconButton, Icon, Tooltip },
  props: {
    label: {
      type: String,
      required: true
    },
    variant: String,
    bold: Boolean,
    color: String,
    error: String,
    errorMessage: String,
    canClose: Boolean
  },
  setup(props, { emit }) {
    return {
      onClickClose: () => emit('close')
    };
  }
});
</script>

<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/effect';

.ui-tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--primary-light-color);
  border-radius: 8px;
  padding: $spacing-base $spacing-base * 2;

  &.outlined {
    background-color: var(--contrast-color);
    border: 1px solid var(--primary-color);

    .ui-tag-close {
      @extend .hover-background;
    }
  }

  &.error {
    border: 1px solid var(--red-blue_red-5);
    background-color: var(--contrast-color);
  }
}

.ui-tag-close {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
</style>
